import objectMap from './object-map'
import pipe from './pipe'

export const convertDateToString = value => (value instanceof Date ? value.toString() : value)
export const convertUndefinedToNull = value => (typeof value === 'undefined' ? null : value)
export const isObjectNotNull = value => typeof value === 'object' && !Array.isArray(value) && value !== null
const MAX_DEPTH = 20
export function serialize(objects, depth = 0) {
    if (Array.isArray(objects)) {
        return objects.map(object =>
            pipe(
                convertDateToString,
                val => {
                    if (Array.isArray(val)) {
                        return serialize(val, depth)
                    }

                    return isObjectNotNull(val) ? serialize(val, depth) : val
                },
                convertUndefinedToNull
            )(object)
        )
    }

    return objects
        ? objectMap(objects, value =>
              pipe(
                  convertDateToString,
                  val => {
                      if (Array.isArray(val)) {
                          return serialize(val, depth)
                      }
                      if (depth >= MAX_DEPTH) {
                          throw new Error(
                              'Maximum object depth exceeded. ' +
                                  'A circular reference was passed. ' +
                                  'Probably an error during ssr fetching'
                          )
                      }
                      return isObjectNotNull(val) ? serialize(val, depth + 1) : val
                  },
                  convertUndefinedToNull
              )(value)
          )
        : null
}

export function serializeObject(object) {
    return serialize(object)
}

export function serializeObjects(arrayOfObjects) {
    return serialize(arrayOfObjects)
}
