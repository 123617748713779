/**
 * Taken from: https://www.freecodecamp.org/news/pipe-and-compose-in-javascript-5b04004ac937/
 * @example
 * pipe(
 *   getName,
 *   uppercase,
 *   get6Characters,
 *   reverse
 * )({ name: 'Buckethead' });
 * // output: TEKCUB
 */
const pipe =
    (...fns) =>
    x =>
        fns.reduce((v, f) => f(v), x)

export default pipe
