/**
 * Taken from: https://stackoverflow.com/a/14810722
 *
 * @example
 * const myObject = { a: 1, b: 2, c: 3 }
 * console.log(objectMap(myObject, v => 2 * v))
 */
const objectMap = (obj, fn) => Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))

export default objectMap
