import { DeviceType } from './device-type'

export async function preloadWidgetData(widgetList, componentGetDataMap, ComponentType, entityList) {
    return Promise.allSettled(
        widgetList.map(async widget => {
            if (widget.responsive) {
                const allResponsiveItems = Object.values(DeviceType).reduce((items, deviceType) => {
                    if (!widget.responsive[deviceType]) {
                        return items
                    }

                    const foundEntities = widget.responsive[deviceType].items
                        .map(item => entityList.find(entity => entity.id === item.id))
                        .filter(Boolean)

                    foundEntities.forEach(entity => {
                        items.set(entity.id, entity)
                    })

                    return items
                }, new Map())

                widget.props = {
                    ...widget.props,
                    preloadedItems: Array.from(allResponsiveItems.values())
                }
            }

            if (typeof componentGetDataMap[widget.component] === 'function') {
                const widgetData = await componentGetDataMap[widget.component](widget.props)

                widget.props = {
                    ...widget.props,
                    ...widgetData
                }
            }

            if (widget.component === ComponentType.GROUP) {
                widget.props.widgetList = await preloadWidgetData(
                    widget.props.widgetList,
                    componentGetDataMap,
                    ComponentType,
                    entityList
                )
            }
            return widget
        })
        // @TODO: connect errors to a watcher
    ).then(results => results.filter(r => r.status === 'fulfilled').map(r => r.value))
}
